
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {namespace} from "vuex-class";
import ContainerStatisticsRepository from "@/api/repositories/ContainerStatisticsRepository";
import ContainerStatistics from "@/models/ContainerStatistics";
import ContainerType from "@/models/ContainerType.model";
import {
  CONTAINER_TYPE_STORE_NAME,
  ContainerTypeStoreActions,
  ContainerTypeStoreGetters
} from "@/store/container-type.store";
import {ROUTE_CONTAINERS_OVERVIEW} from "@/router/routes";

const ContainerTypeStore = namespace(CONTAINER_TYPE_STORE_NAME);

@Component({
  components: {
    ContainerStatisticsComponent: () => import(
      /* webpackChunkName: "ContainerStatisticsComponent" */
      '@/components/ContainerType/ContainerStatistics.component.vue'
    ),
  },
})
export default class RentedContainersView extends Vue {
  @ContainerTypeStore.Getter(ContainerTypeStoreGetters.GET_CONTAINER_TYPES)
  private containerTypes!: ContainerType[];

  @ContainerTypeStore.Action(ContainerTypeStoreActions.GET_ALL)
  private getAllContainerTypesAction!: (companyId: string) => Promise<ContainerType[]>;

  /**
   * object that holds all the information about the statistics
   */
  private statistics: ContainerStatistics = new ContainerStatistics();

  /**
   * flag that indicates if the screen has data
   */
  private hasStatisticsData = true;

  /**
   * list of ContainerType types, normally use those from the store
   */
  private containers!: ContainerType[];

  /**
   * the container that should be shown here
   */
  private container?: ContainerType;

  private get chartColor() {
    return this.$vuetify.theme.currentTheme['primary']?.toString();
  }

  /**
   * gets the container from the store by checking the id that is set in the route
   */
  private async created() {
    try {
      this.isLoading = true;

      // gets the id from the route
      const id = this.$route.params.containerTypeId;

      // sets the ContainerType by the stored container types, if no ContainerType are stored yet, it fetches the containers
      // and uses that value
      this.containers = this.containerTypes;
      if(!this.containers) {
        this.containers = await this.getAllContainerTypesAction(this.$route.params.id);
      }

      // gets the containerType from the store that has the same id
      this.container = this.containers.find((c: ContainerType) => c.id == id);

      // fetches the statistics of the containerType
      const response = await ContainerStatisticsRepository.getStatisticsByCompany(this.$route.params.id, id);
      this.statistics = ContainerStatistics.parseFromObject(response.data);
    } catch (e) {
      // if user uses invalid url (e.g. wrong container type id, the user is redirected to the overview)
      if(e.status === 404) {
        await this.$router.push({name: ROUTE_CONTAINERS_OVERVIEW});
      }

      // container type has no data yet, sets flag to notify the statistics about it
      if(e.status === 412) {
        this.hasStatisticsData = false;
      }
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * flag that indicates if the view is loading
   * @private
   */
  private isLoading: boolean = false;
}

